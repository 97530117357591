import styled from 'styled-components';
import { red, tablet, desktop } from '../constants';

const Title = styled.h2`
  text-align: center;
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 2rem;
  line-height: 1;
  color: ${red};

  @media (min-width: ${tablet}) {
    font-size: 2.5rem;
  }

  @media (min-width: ${desktop}) {
    font-size: 3.5rem;
  }
`;

export default Title;
