import React, { useContext } from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import A11yModeContext from '../contexts/A11yModeContext';
import Layout from '../components/Layout';
import Title from '../components/Title';
import { green, red, yellow, tablet, desktop } from '../constants';
import SEO from '../components/seo';
import chevron from '../components/icons/chevron.svg';
import ContactForm from '../components/ContactForm';

const Heading = styled.h3`
  color: ${red};
  font-size: 1.25rem;
  font-weight: 800;

  @media (min-width: ${desktop}) {
    font-size: 2rem;
    line-height: 1.5;
  }
`;

const fontSizes = {
  Normal: { mobile: 1, tablet: 1.25, desktop: 1.5 },
  Large: { mobile: 1.25, tablet: 1.5, desktop: 1.75 },
  Small: { mobile: 1, tablet: 1, desktop: 1.25 }
};

const Intro = styled.div`
  & > p {
    font-size: ${({ size }) => fontSizes[size]?.mobile}rem;
    line-height: 1.5;
    color: ${green};
    font-weight: 300;

    @media (min-width: ${tablet}) {
      font-size: ${({ size }) => fontSizes[size]?.tablet}rem;
    }

    @media (min-width: ${desktop}) {
      font-size: ${({ size }) => fontSizes[size]?.desktop}rem;
    }
  }
`;

const Description = styled.div`
  & > p, & > ul {
    line-height: 1.5;
    color: ${green};
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 1rem;

    @media (min-width: ${tablet}) {
      font-size: 1.125rem;
    }
  }
`;

const Item = styled(AccordionItem)`
  border-bottom: 1px solid ${red};
  &:first-child {
    border-top: 1px solid ${red};
  }

  & ul {
    list-style: disc;
    margin: 1rem;
  }

  & li {
    margin-bottom: 0.25rem;
  }
`;

const Chevron = styled.img`
  height: 24px;
  width: 24px;
  transition: transform 0.3s;
`;

const Button = styled(AccordionItemButton)`
  padding: 1rem 0;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &[aria-expanded='true'] ${Chevron} {
    transform: rotate(180deg);
  }

  ${({ isA11yMode }) =>
    isA11yMode
      ? `&:focus {
    background: ${yellow};
  }`
      : ''}
`;

const StyledAccordion = styled(Accordion)`
  margin: 3rem 0;
`;

const Formats = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3rem 0 1.5rem;
`;

const HeadingFontSizes = {
  Medium: 2.5,
  Large: 3,
  Small: 2
};

const Text = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${({ size }) => HeadingFontSizes[size] || 3}rem;
  text-align: center;
  display: none;

  @media (min-width: ${desktop}) {
    display: flex;
  }
`;

const FormatDescription = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1rem;
  text-align: center;
  display: none;
  background: rgba(0,0,0,0.6);
  padding: 1rem;
  flex-direction: column;
  line-height: 1.25;
  flex-shrink: 1;
  max-height: 100%;

  & ul {
    list-style: disc;
    margin: 1rem;
  }

  & li {
    margin-bottom: 0.25rem;
  }

  @media (min-width: ${desktop}) {
    font-size: 1.25rem;
  }
`;

const SquareImage = styled.div`
    position: relative;
    width: 32%;
    padding-bottom : 32%;
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    background-image: url(${({ image }) => image});
    flex-shrink: 0;

    @media (min-width: ${desktop}) {
      width: 100%;
      padding-bottom: 100%;
    }
`;

const Mobile = styled.div`
  margin-left: 1rem;

  @media (min-width: ${desktop}) {
    display: none;
  }
`;

const MobileText = styled.div`
  color: ${red};
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 1rem;
`;

const MobileDescription = styled.div`
  & > p, & > ul {
    line-height: 1.5;
    color: ${green};
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  & ul {
    list-style: disc;
    margin: 1rem;
  }

  & li {
    margin-bottom: 0.25rem;
  }
`;

const Format = styled.li`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: flex-start;

  @media (min-width: ${desktop}) {
    width: 32%;
    display: block;

    &:hover, &:focus {
      & ${Text} {
        display: none;
      }

      & ${FormatDescription} {
        display: flex;
      }
    }
  }
`;

export const query = graphql`
  {
    prismic {
      services(uid: "services", lang: "en-gb") {
        title
        description
        size
        formats {
          image1
          title1
          size1
          description1
        }
        size2
        description2
        services {
          title
          description1
        }
      }
    }
  }
`;

const Services = ({ data }) => {
  const { title, description, services, size = "Normal", formats, size2 = "Normal", description2 } = data.prismic.services;
  const isA11yMode = useContext(A11yModeContext);

  return (
    <Layout>
      <SEO title="Services" />
      {title && <Title>{RichText.asText(title)}</Title>}
      {description && <Intro size={size}>
        <RichText render={description}></RichText>
      </Intro>}
      <Formats>
      {formats && formats.map(({ image1, title1, description1, size1 = "Large" }) => (
        <Format tabIndex="0">
          <SquareImage image={image1?.url}>
            <Text size={size1}><RichText render={title1} /></Text>
            <FormatDescription><RichText render={description1} /></FormatDescription>
          </SquareImage>
          <Mobile>
            <MobileText><RichText render={title1} /></MobileText>
            <MobileDescription><RichText render={description1} /></MobileDescription>
          </Mobile>
        </Format>
      ))}
      </Formats>
      {description2 && <Intro size={size2}>
        <RichText render={description2}></RichText>
      </Intro>}
      <StyledAccordion
        allowZeroExpanded
        onChange={uuids =>
          uuids.length > 0 &&
          scrollIntoView(
            document.querySelector(`#accordion__panel-${uuids[0]}`),
            { block: 'center', scrollMode: 'if-needed' }
          )
        }
      >
        {services && services.map(({ title, description1 }) => (
          <Item key={RichText.asText(title)}>
            <AccordionItemHeading>
              <Button isA11yMode={isA11yMode}>
                <Heading>{RichText.asText(title)}</Heading>
                <Chevron src={chevron} alt="Toggle Section" />
              </Button>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Description>
                <RichText render={description1}></RichText>
              </Description>
            </AccordionItemPanel>
          </Item>
        ))}
      </StyledAccordion>
      <ContactForm />
    </Layout>
  );
};

export default Services;
